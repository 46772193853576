import { CameraAlt, CameraswitchOutlined } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { MAX_WIDTH, MAX_WIDTH_HALF } from '../../helpers';

const WebcamCapture = ({ setImage, buttonRef, flipButtonRef, aspectRatio }: any) => {
  const webcamRef = useRef<Webcam>(null);

  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('environment');
  const capture = React.useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      console.log('imageSrc', imageSrc);
      setImage(imageSrc);
    }
  }, [setImage]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        padding: '1em',
        width: '100%',
        maxWidth: `${MAX_WIDTH}px`,
        background: '#ffffff',
        height: '100%',
        margin: 'auto',
      }}
    >
      <div
        style={{
          width: '100%',
          maxWidth: `${MAX_WIDTH}px`,
          border: '3.5px dashed #FFFFFF',
        }}
      >
        <Webcam
          ref={webcamRef}
          videoConstraints={{
            facingMode: facingMode,
            aspectRatio: aspectRatio,
          }}
          screenshotFormat="image/jpeg"
          style={{ width: '100%', height: 'auto' }}
        />
      </div>

      <Button
        ref={buttonRef}
        variant="outlined"
        color="secondary"
        sx={{
          display: 'none',
          padding: '1em 2em',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '45%',
          maxWidth: MAX_WIDTH_HALF,
        }}
        onClick={capture}
      >
        <CameraAlt />
        &nbsp; Capture
      </Button>

      <Button
        ref={flipButtonRef}
        variant="outlined"
        color="primary"
        sx={{
          display: 'none',
          padding: '1em 2em',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'absolute',
          top: '5em',
          right: '2em',
          width: '45%',
          maxWidth: MAX_WIDTH_HALF,
        }}
        onClick={() =>
          setFacingMode((prev) => (prev === 'user' ? 'environment' : 'user'))
        }
      >
        <CameraswitchOutlined />
        &nbsp; Flip Camera
      </Button>
    </Grid>
  );
};

export default WebcamCapture;
